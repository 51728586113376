.document-viewer {
  .page {
    width: 793px;
    min-height: 29.7cm;
    margin: 0 auto;
    margin: 2px auto 0.2cm auto;
    box-shadow: 0 0 2px #777;
    padding: 2rem 6rem;
    overflow: hidden;

    .page-content {
      .section {
        .out-of-page {
          background-color: #FBB !important;
        }
        &.edit-mode{
          margin-bottom: 8px;
        }
        .section-content{
          padding-left: 18px;
          padding-right: 1px;
          &.no-pad{
            padding-left: 0;
          }
          .cke_editable{
            img{
              max-width: 100%;
            }
          }
        }
        .action{
          .font-input .container{
            background-color: #FFF;
          }
        }
        .sl-no {
          width: 14px;
          display: inline-block;
          margin-right: 4px;
        }
        .section-content-html{
          overflow: hidden;
          white-space: normal;
          img{
            max-width: 100%;
          }
        }
        &.sub-section {
          padding-left: 18px;

          .sl-no {
            width: 28px;
          }
          .section-content{
            padding-left: 32px;
          }
        }
        .font-input{
          margin-bottom: 0;
          .label-cont{
            min-width: 0;
            width: 0;
          }
        }
      }
      &::-webkit-scrollbar {
        width: 0px;
        /* Remove scrollbar space */
        background: transparent;
        /* Optional: just make scrollbar invisible */
      }

      /* Optional: show position indicator in red */
      &::-webkit-scrollbar-thumb {
        background: #238787;
      }
    }
  }

  .page-header {
    line-height: 1.8;
    margin-bottom: 16px;

    .fist-sec {
      flex: 1.5;
    }

    .logo {
      max-width: 200px;
      height: 64px;
      object-fit: contain;

      &.placeholder {
        background-color: #BDBDBD;
        border-radius: 8px;
      }
    }

    .logo-sec {
      border-bottom: 1px solid #777;
      margin-bottom: 0.8rem;
      position: relative;
    }

    .key {
      width: 64px;
      color: #5A5A5A;

      &.small {
        width: 32px;
      }

      &.gap {
        margin-right: 12px;
      }
    }

    .page-sec {
      position: absolute;
      right: 0.8rem;
      bottom: 0;
      margin-top: 1.6rem;
    }

    .classif {
      padding: 0 8px !important;
      line-height: unset;

      &.internal {
        color: #FF5522;
      }

      &.public {
        color: #44bb44;
      }

      &.restricted {
        color: #c55911;
      }

      &.confidential,
      &.personal {
        color: #FFF;
        background-color: rgb(175,5,17);
        margin-left: 8px;
        padding: 0 8px;
      }
    }

    .doc-status {
      margin-right: 4px;

      &.draft {
        color: rgb(187, 34, 34);
      }

      &.reviewed {
        color: rgb(255,85, 34);
      }

      &.approved {
        color: rgb(68, 187, 68);
      }

      &.published {
        color: rgb(34, 136, 34);
      }
      &.archived {
        color: rgb(136, 136, 136);
      }
    }

  }

  .footer {
    position: relative;
    border-top: 1px solid #777;

    .txt {
      margin-top: 0;
      margin-bottom: 0.4rem;
      line-height: 1.35;
    }

    .version {
      .pres {
        margin-right: 1.6rem;
      }
    }
  }

  .doc-title {
    margin: 52px 0;
  }

  .history-tbl {
    margin-bottom: 24px;
    margin-top: 4px;
    table-layout: fixed;
    border-spacing: 0;

    .cell {
      padding: 1px 8px;
      p{
        margin: 0;
      }
    }

    thead {
      th.cell {
        border-right-width: 1px;
        border-right-style: solid;
        border-right-color: #FFF;
        &:last-child {
          border-right-color: #238787 !important;
        }
      }
    }

    tbody {
      .cell {
        border-left-width: 1px;
        border-left-style: solid;
        border-left-color: #238787;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #238787;
        vertical-align: top;
      }

      tr .cell:last-child {
        border-right-width: 1px;
        border-right-style: solid;
        border-right-color: #238787;
      }

      tr:nth-child(even) {
        .cell {
          background-color: #FFF;
        }
      }
    }

    &.schema {
      thead {
        th.cell {
          &:last-child {
            border-right-color: #8B0000 !important;
          }
        }
      }

      tbody {
        .cell {
          border-left-color: #8B0000;
          border-bottom-color: #8B0000;
        }
        tr .cell:last-child {
          border-right-color: #8B0000;
        }
      }
    }
  }

  .section {
    .title:hover .title-menu {
      visibility: visible;
    }

    .title-menu {
      padding: 0;
      width: 18px;
      height: 18px;
      margin-left: 8px;
      background-color: transparent;
      visibility: hidden;
      box-shadow: none;
      align-self: unset;

      .icon {
        font-size: unset;
      }

      &:hover {
        display: inline;
      }
    }
  }
  .tbl-cont-line{
    width: 100%;
    clear: both;
    line-height: 2;
    margin-bottom: 16px;
    .section{
      float: left;
      text-transform: capitalize;
    }
    .pageno{
      float: right;
      &::after{
        clear: both;
      }
    }
    
  }
  .tbl-cont-row{
    margin-bottom: 4px;
    margin-top: 12px;
    position: relative;
    .first{
      margin-left: 40px;
      font-weight: 400;
    }
    &.sub-section {
      padding-left: 24px;
      width: calc(100% - 0px);
      margin-top: 4px;
    }
    .text:hover{
      color: #0133CC;
    }
  }
}

.cke_float {
  max-width: calc(21cm - 12rem);
}